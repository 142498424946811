
*{
    cursor: none; 
  }
  .cursor-dot, 
  .cursor-dot-outline{
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    opacity: 1;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  }
  
  .cursor-dot{
    z-index:3;
    width: 8px;
    height: 8px;
    background-color: #00af73;
  } 
  .cursor-dot-outline{
    width: 40px;
    z-index: 3;
    height: 40px;
    background-color: rgba(156, 217, 196, 0.5);
    
  }
  
  
  


